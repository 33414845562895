.about h2 {
    margin-top: 2rem;
    font-size: 44px;
}

.about-box {
    border-radius: 1rem;
    box-shadow: 0 0 2.8rem rgba(0,0,0,.15);
    padding: 0rem 2rem;
}

.about-col {
    width: 48%;
}

@media (max-width: 33em) {
    .about {
        margin-left: 2rem;
    }

    .about-box {
        padding: 0rem 1rem;
        width: 90%;
    }

    .about-box p {
        font-size: 1rem;
    }

    .about-col {
        width: 95%;
    }
}