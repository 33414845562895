.home {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
    background-image: url("../../media/banner-web-development.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.hero-col {
    width: 50%;
}

.hero-info h1 {
    font-family: var(--base-font);
    font-size: var(--h1-size);
    margin: 3rem 0;
}

.hero-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.hero-description {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: rgb(255, 255, 255);
}

@media (max-width: 50em) {    
    .hero-info h1 {
        font-size: 50px;
    }
}

@media (max-width: 33em) { 
    .home {
        padding: 0 1rem;
        width: 110vw;
    }
    .hero-col {
        width: 50%
    }

    .hero-info h1 {
        font-size: 30px;
    }

    .hero-info h3 {
        font-size: 20px;
    }

    .hero-info p {
        font-size: 14px;
    }
}