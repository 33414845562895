h2 {
    font-size: 44px;
    margin-bottom: 1rem;
}

.contact {
    margin-bottom: 5rem;
}
  
form {
    width: 80%;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    margin: 0 auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    position: relative;
}

input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    transition: all .3s;
    border-bottom: 2px solid #bebed2;
    margin: 10px 0;
}

input:focus {
    border-bottom:2px solid #78788c;
}

input:before {
    content: attr(type);
    display: block;
    margin: 50px 0 0;
    font-size: 16px;
    color: #5a5a5a;
}
  
.form-group {
    margin-bottom: 1rem;
}
  
label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}
  
input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}
  
textarea {
    resize: vertical;
}
  
button {
    margin: 20px 40% 0;
    padding: 8px 12px;
    border: 2px solid #6380ff;
    border-radius: 10px;
    background: 0;
    color: #000000;
    cursor: pointer;
    transition: all .3s;
    font-size: 16px;
}
  
button:hover {
    background:#6380ff;
    color:#fff;
}  

@media (max-width: 33em) {
    form {
        width: 90%;
        margin-left: 10%;
    }

    .button {
        margin-left: 60px;
        margin-right: 10px;
    }
}