@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');

:root {
    --base-color: #5d6dff;
    --base-font: 'Raleway', 'sans-serif';
    --second-color: #016fff;
    --bg-color: #272727;
    --h1-color: #0e6ae2;
    --h2-color: #667ce0;
    --h3-color: #1f1f1f;
    --heading-color: #16d2f3;
    --p-color: #3d3b36;
    --body-color: #3b3629;
    --h1-size: 62px;
    --h2-size: 44px;
    --h3-size: 24px;
    --p-size: 18px;
    --transition: all .4s ease;
}

html {
    scroll-behavior: smooth;
}
  
* {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    border: 0;
}
  
body {
    font-family: var(--base-font);
    color: var(--body-color);
}
h1, h2, h3 {
    padding: 10px 0;
}
  
h1 {
    font-size: var(--h1-size);
    color: var(--h1-color);
}
  
h2 {
    font-size: var(--h2-size);
    color: var(--h2-color);
}
  
h3 {
    font-size: var(--h3-size);
    color: var(--h3-color);
}
  
p {
    padding: 10px 0;
    line-height: 30px;
    font-size: var(--p-size);
    color: var(--p-color);
}


.container {
    width: 1320px;
    margin: 0 auto;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px 10px;
}

.center {
    justify-content: center;
}


@media (max-width: 69em) {
    .container {
        max-width: 55em;
        margin: 0 auto;
    }
    
    .row {
        gap: 40px 14px;
        align-items: center;
        flex-shrink: 0;
    }
}

@media (max-width: 33em) {
    .container {
        max-width: 22em;
        
    }
    .row {
        gap: 40px 14px;
        align-items: center;
        flex-shrink: 0;
    }
}