.header {
    z-index: 99;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    height: 5rem;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 3.2rem;
    background: rgba(53, 115, 247, 0);
}

.header .brand {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
    z-index: 100;
}

.header-image {
    width: 40%;
}

.header .mobile-nav-circle {
    display: none;
}

.scroll-down {
    background-color: #fff;
    box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.2);
}

.nav-mobile-btn {
    display: none;
}

.nav-mobile-btn .mobile-icon-close {
    display: none;
}

.nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.nav-link:link, .nav-link:visited {
    font-size: 1.4rem;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;
}

.nav-link:hover, .nav-link:active {
    color: var(--base-color);
}

@media (max-width: 69em) {

    .nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--bg-color);
        opacity: 0;
        /* 2) Make it unaccessible to mouse and keyboard */
        pointer-events: none;
        /* 3) Hide it from screen readers */
        visibility: hidden;
        transition-delay: 0.2s;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }

    .nav-link:link, .nav-link:visited {
        font-size: 2rem;
    }

    .nav-mobile-btn {
        position: relative;
        display: block;
        border: none;
        background-color: transparent;
        color: var(--bg-color);
        margin-left: 85%;
    }

    .nav-open .mobile-icon-close {
        display: block;
    }
    .nav-open .mobile-icon-open {
        display: none;
    }

    .nav-open .nav {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }

    .header .brand {
        transition-delay: .2s;
    }

    .header-image {
        width: 100%;
    }

    .header .mobile-nav-circle {
        display: inline-block;
        z-index: -1;
        background-color: rgba(255, 163, 77, 0.534);
        backdrop-filter: blur(5px);
        border-radius: 50%;

        /* max screen size for mobile screen is 61em * 16px = 976px(97.6rem) */
        width: 300vmax;
        height: 300vmax;

        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.5s;
    }

    .nav-open {
        & > .mobile-nav-circle {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .nav-open > .header .breand {
        color: white;
    }
}