.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
}
  
.footer-logo img {
    width: 50%;
}
  
.footer-email p {
    margin-top: 0.5rem;
    color: white;
    margin-left: -18rem;
}
  
.footer-shortcuts ul {
    list-style: none;
    padding: 0;
}

.footer-shortcuts li {
  margin-bottom: 0.7rem;
}
  
.footer-shortcuts a {
    text-decoration: none;
    color: #fff;
}
  
.footer-shortcuts a:hover {
    text-decoration: underline;
}

.border-bottom {
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 5px;
}

.footer-copyright p {
    color: #ffffff;
    font-size: 12px;
}
  
@media (max-width: 39em) {
    .footer {
        background-color: white;
    }

    .footer .row {
        border-top: 2px solid #d2d2d2;
    }

    .footer h3, .footer-email p, .footer-shortcuts ul a, .footer-copyright p {
        color: black;
    }

    .footer-column {
        width: 100%;
    }

    .footer-logo {
        margin-left: 40%;
        width: 90%;
    }

    .footer-email {
        margin-left: 18rem;
        text-align: center;
    }
}
@media (max-width: 33em) {
    .footer-column {
        width: 100%;
    }

    .footer {
        padding-left: 8px;
    }

    .footer-shortcuts li {
        text-align: center;
    }
}