.services h2 {
    margin-top: 2rem;
    font-size: 44px;
}

.services-col {
    width: 48%;
}



@media (max-width: 55em) {
    .services-img {
        width: 90%;
    }

    .services-col {
        width: 100%;
    }
}